<template>
  <div class="sms_code_reg">
    <div class="top_card">
      <img class="none_img" src="@/assets/uzum-logo.svg" alt="" />

      <div class="pagination">
        <div class="prev">
          <img src="../../assets/profile_assets/chevron-left.svg" alt="" />
        </div>

        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>

        <div class="next">
          <img
            src="../../assets/profile_assets/cevron-right-orange.svg"
            alt=""
          />
        </div>
      </div>

      <h2>{{ $localize('sms-code')['title'] }}</h2>

      <p v-if="number" class="number">
        {{ $localize('sms-code')['text'] }} <span>{{ number }}</span>
      </p>

      <p v-else>{{ $localize('sms-code')['text'] }} +998 99 *** ** 44</p>
    </div>

    <div class="form_input">
      <sms-code-input
        v-model="smsCode"
        class="fields-container"
        :length="4"
        input-type="tel"
        pattern="[^0-9]+"
        :ignorePattern="false"
        fieldClass="custom-field-class"
        :size="32"
        :disabled="false"
        type="tel"
      />
    </div>

    <p-button
      class="button"
      :to="{path: $route.path}"
      :loading="loading"
      @click="loadingHandler"
    >
      {{ $localize('button')['continue'] }}
    </p-button>

  </div>
</template>

<script>

export default {
  name: 'smsCodeRegistration',
  data: () => ({
    loading: false,
    smsCode: '',
    number: '',
    api_token: null,
  }),
  methods: {
    loadingHandler() {
      this.loading = true;
      this.$toasted.clear();
      setTimeout(() => {
        this.$axios
          .post('buyer/check-sms-code-uz', {
            card_number: localStorage.getItem('card_number'),
            card_valid_date: localStorage.getItem('card_valid_date'),
            code: this.smsCode
          })
          .then((res) => {
            if (res.data.status == 'success') {
              this.loading = false;

              this.$store.dispatch('buyers/userStatus').then(() => {
                this.$toasted.clear();
                this.$router.push({ name: 'upload-passport' });
              });
            } else {
              this.loading = false;

              this.$toastError(this.$localize('error')['code']);
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toastError(e.message);
          });
      }, 2000);
    }
  },
  mounted() {
    this.number = localStorage.getItem('number');
    this.api_token = this.$cookieGet('token');

    const inp = document.querySelectorAll('.custom-field-class input');
    inp.forEach((n) => {
      n.type = 'tel';
    });
  }
};
</script>

<style lang="scss" scoped>
.top_card {
  padding-top: 17%;

  img.none_img {
    margin-bottom: 56px;
  }

  h2 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 42px;
  }
}

.pagination {
  display: none;
}

button {
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  height: 100%;
  max-width: calc(83px * 4);
  margin-top: 42px;

  &.disabled {
    cursor: not-allowed;
  }
}

.button {
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  height: 100%;
  max-width: calc(83px * 4);
  margin-top: 42px;

  &.disabled {
    cursor: not-allowed;
  }
}

@media (max-width: 1199px) {
  .sms_code_reg {
    height: 100vh;
    display: flex;
    text-align: center;
    /*justify-content: center;*/
    flex-direction: column;
    align-items: center;

    .top_card {
      padding-top: 0px;
    }

    .form_input:nth-child(3) {
      width: 100%;
    }

    button {
      max-width: 332px;
    }
  }
}

@media (max-width: 575px) {
  .top_card h2 {
    font-size: 32px;
    line-height: auto;
  }
  .h-100 {
    height: 100%;
  }
  .form_input.button {
    margin-top: auto;
    line-height: 16px;
  }
}

@media (max-width: 575px) {
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    margin-top: 0px;

    .prev {
      transform: rotate(180deg);
      display: flex;
      align-items: center;
    }

    .next {
      display: flex;
      align-items: center;
    }

    ul {
      display: flex;
      align-items: center;
      position: relative;

      li {
        width: 12px;
        height: 12px;
        background: $main;
        border-radius: 50%;
        margin: 0px 8px;
        position: relative;
        z-index: 2;

        &:first-child {
          width: 8px;
          height: 8px;
          background: $main;
          border-radius: 50%;
        }

        &:last-child {
          width: 8px;
          height: 8px;
          background: #a5a5a5;
          border-radius: 50%;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% - 16px);
        height: 1px;
        background: linear-gradient(to right, #6610F5 50%, #a5a5a5 50%);
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .sms_code_reg {
    height: calc(100vh);
    padding: 30px 0 100px 0;
  }
  .top_card h2 {
    font-weight: 900;
    font-size: 24px;
    line-height: 40px;
    color: $black;
    margin-bottom: 8px;
  }
  .top_card p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $black;
    display: flex;
    flex-direction: column;
    margin-bottom: 38px;
  }
  button {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    height: auto;
  }
  .button {
    margin-top: 48px;
    margin-bottom: 32px;
  }
  .h-100 {
    height: 100%;
  }
  .sms_code_reg .top_card .none_img {
    display: none;
  }
}
</style>
<style lang="scss">
.fields-container {
  display: flex;
  justify-content: flex-start;

  .custom-field-class {
    padding: 0px 8px;

    input {
      font-family: 'Inter', sans-serif;
      width: 67.05px;
      height: 67.05px;
      border: 1.1175px solid #626262;
      // padding-left: 24px;
      background: #f5f5f5;
      text-align: center;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 32px;

      &:focus {
        outline: none;
      }
    }
  }
}

@media (max-width: 575px) {
  .fields-container .custom-field-class {
    padding: 0 3px;

    input {
      width: 55px;
      height: 55px;
    }
  }
}
</style>
